import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Badge from "react-bootstrap/Badge";
import Table from "react-bootstrap/Table";
import axios from "axios";
import Modal from "react-bootstrap/Modal";
import Swal from "sweetalert2";
import { Link, Route, Routes, useNavigate } from "react-router-dom";

const UserPost = () => {
  const [users, setUsers] = useState([]);
  const [totalCounts, setTotalCounts] = useState("");
  const [paidShow, setPaidShow] = useState(false);
  const [status, setStatus] = useState();
  const [serviceId,setServiceId] = useState();
  const [show, setShow] = useState(false);
  const navigate = useNavigate();
  const [userData, setUserData] = useState({
    categoryId: "",
    categoryName: "",
    userId: "",
    servicePrice: 0,
    serviceDescription: "",
    serviceName: "",
    serviceImage: "",
    serviceText: "",
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const userToken = localStorage.getItem("userToken");
  const handleDelete = (item) => {
    console.log(item)
    const confirmed = window.confirm(
      "Are you sure you want to delete this item?"
    );

    if (!confirmed) {
      return;
    }

    let config = {
      method: "delete",
      maxBodyLength: Infinity,
      url: `https://ehb.easyhaibro.com/auth/v1/services/${item}`,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    };

    axios
      .request(config)
      .then((response) => {
        console.log(response);
        if (response.status === 204) {
          Swal.fire({
            icon: 'success',
            title: 'Success!',
            text: 'Service Deleted successfully.',
          });
          fetchData();
        } else {
          Swal.fire({
            icon: 'success',
            title: 'Success!',
            text: 'Service Deleted successfully.',
          });
          fetchData();
        }
      })
      .catch((error) => {
        console.error(error);
        Swal.fire({
            icon: 'success',
            title: 'Success!',
            text: 'Service Deleted successfully.',
          });
        fetchData();
      });
  };

  const handleClose = () => setShow(false);
  const fetchData = async (action) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_ADMIN}services`,
        {
          headers: {
            'Authorization': `Bearer ${userToken}`,
            "Content-Type": "application/json",
          },
        }
      );

    
      setTotalCounts(response.data.totalCounts);
      setUsers(response.data.data);
     
    } catch (error) {}
  };

  useEffect(() => {
    fetchData("approve");
  }, []);

  const handleSave = (val) => {
    console.log(serviceId)
    let config = {
      method: `patch`,
      maxBodyLength: Infinity,
      url: `https://ehb.easyhaibro.com/auth/v1/service/${serviceId}`,
      headers: {
        Authorization: userToken,
        "Content-Type": "application/json",
      },
      data: userData,
    };

    axios
      .request(config)
      .then((response) => {
        fetchData();
        console.log(response.data)
       setShow(false);
      
      })
      .catch((error) => {});
  };

  const [currentPage, setCurrentPage] = useState(1);
  const usersPerPage = 15;


  const indexOfLastUser = currentPage * usersPerPage;
  const indexOfFirstUser = indexOfLastUser - usersPerPage;
  const currentUsers = users.slice(indexOfFirstUser, indexOfLastUser);

  const handleNextPage = () => {
    // Go to the next page
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const handlePrevPage = () => {
    // Go to the previous page
    setCurrentPage((prevPage) => prevPage - 1);
  };

  const handleCmt = (id, subject) => {
    navigate(`/toc/${id}/${subject}`);
  };

  const handleCreate = ()=>{
    navigate(`/dashboard/create-courses`);
   
  }

  const handleEdit = (val)=>{
   
    setServiceId(val)
let config = {
  method: 'get',
  maxBodyLength: Infinity,
  url: `https://ehb.easyhaibro.com/auth/v1/service/${val}`,
  headers: { 
    'Authorization': userToken
  }
};

axios.request(config)
.then((response) => {
  console.log(JSON.stringify(response.data));
  setUserData(response.data)
})
.catch((error) => {
  console.log(error);
});

     setShow(true)
  }
  return (
    <>
      <div className="right_sidebar">
        <div className="rs_in_third card border-0">
          <div className="ehb-row">
             <div className="ehb-70">
              <h2 id="total">Total Listed Services {users.length}</h2>
             </div>
            
          </div>
         
         
          <table>
            <thead>
              <tr>
                <th>SR No</th>
                <th>Service Name</th>
                <th>Services Category</th>
                <th>Services Image</th>
                <th>Price (INR)</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {currentUsers.map((user, i) => (
                <tr key={user.id}>
                  <td>{i + 1 + (currentPage - 1) * usersPerPage}</td>
                  <td>
                    {user.serviceName}
                    
                  </td>

                  <td onClick={handleCmt.bind(null, user._id, user.subject)}>
                   {user.categoryName}
                  </td>

                  <td>
                    <img src={user.serviceImage} width="50px" alt="" />
                  </td>
                  <td>₹{user.servicePrice}</td>
                  <td>
                    <button onClick={() => handleDelete(user._id)}>
                      Delete
                    </button>
                    <button onClick={() => handleEdit(user._id)}>
                      Edit
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="pages">
            <button onClick={handlePrevPage} disabled={currentPage === 1}>
              Prev
            </button>
            <button
              onClick={handleNextPage}
              disabled={indexOfLastUser >= users.length}
            >
              Next
            </button>
          </div>
        </div>
      </div>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{userData.serviceName}'s Details</Modal.Title>
        </Modal.Header>
        <Modal.Body id="table">
          
          <tr>
            <td>Service Name</td>
            <td>
              <input
                type="text"
                placeholder=""
                value={userData.serviceName}
                onChange={handleChange}
                name="serviceName"
                required
              />
            </td>
          </tr>

          <tr>
            <td>Service Category</td>
            <td>
              <input
                type="text"
                placeholder=""
                value={userData.categoryName}
                onChange={handleChange}
                name="categoryName"
                required
              />
            </td>
          </tr>

   
          <tr>
            <td>Service Price</td>
            <td>
              <input
                type="text"
                placeholder=""
                value={userData.servicePrice}
                onChange={handleChange}
                name="servicePrice"
                required
              />
            </td>
          </tr>

   
          <tr>
            <td>Service Desc</td>
            <td>
              <textarea
              id="textarea"
                type="text"
                placeholder=""
                value={userData.serviceDescription}
                onChange={handleChange}
                name="serviceDescription"
                required
              />
            </td>
          </tr>

          <tr>
            <td>Service Image</td>
            <td>
              <img src={`${userData.serviceImage}`} id="serviceimg"/>
            </td>
          </tr>

   
 
  
        </Modal.Body>
        <Modal.Footer>
          <button id="btn" onClick={()=>handleSave(userData)}>
            Save Changes
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default UserPost;
