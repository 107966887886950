import React, { useState } from "react";
import Login from "./screen/auth/Login";
import { Routes, Route } from "react-router-dom";
import "../src/assets/css/desktop.css";
import Dashboard from "./screen/Dashboard/Dashboard";
import "bootstrap/dist/css/bootstrap.min.css";
import PhoneNumberLookup from "./screen/packerMovers/phone";
import TrackOrders from "./screen/orders/TrackOrder";
import Orders from "./screen/orders/allOrders";
import RightDash from "./screen/Dashboard/RightDash";

const App = () => {
  const [show, setShow] = useState();
  return (
    <>
      <Routes>
        <Route path="/" element={<Login setShow={setShow} />} />
        <Route path="/dashboard/*" element={<Dashboard setShow={setShow} />} />
        <Route path="/track-order/:orderId" element={<TrackOrders setShow={setShow} />} />
        <Route path="/dashboard/orders" element={<><RightDash /></>} />
        <Route path="/phone" element={<PhoneNumberLookup setShow={setShow} />} />
      </Routes>
    </>
  );
};

export default App;
