import React, { useEffect, useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";

const Hackathons = () => {
  const userToken = localStorage.getItem("userToken");

  const [formData, setFormData] = useState({
    postQuestion: "",
    postAnswer: "",
    postImage: null,
    postPrivacy: "public",
  });
  const handleChange = (event) => {
    const { name, value, type } = event.target;
    const newValue = type === "file" ? event.target.files[0] : value;
    setFormData((prevState) => ({
      ...prevState,
      [name]: newValue,
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    let data = JSON.stringify({
      recipients: [formData.email],
      subject: formData.subject,
      fullName: formData.fullName,
      template: formData.template,
    });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: "http://localhost:3001/auth/v1/mailer/send/bulk",
      headers: {
        Authorization: userToken,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        Swal.fire({
          icon: "success",
          title: "Sended Successfully",
          showConfirmButton: false,
          timer: 1500,
        });
        window.location.reload();
      })
      .catch((error) => {});
  };

  return (
    // klmkelke
    <>
      <div className="right_sidebar">
        <div className="rs_in_third card border-0">
          <form onSubmit={handleSubmit}>
            <label>Recipients</label>
            <textarea
              placeholder="write in double quote then comma (eg)"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
            <label>Common Name</label>
            <input
              type="text"
              name="fullName"
              value={formData.fullName}
              onChange={handleChange}
              required
            />
            <label>Subject</label>
            <input
              type="text"
              name="subject"
              value={formData.subject}
              onChange={handleChange}
              required
            />

            <label>Template</label>
            <input
              type="text"
              name="template"
              value={formData.template}
              onChange={handleChange}
              required
            />

            <button type="submit">Submit</button>
          </form>
        </div>
      </div>
    </>
  );
};

export default Hackathons;
