import React, { useState } from 'react';

const PhoneNumberLookup = () => {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [name, setName] = useState('');

  const handleLookup = async () => {
    const accountSid = 'ACeaea1e8edeba07d2297d8c85cff4b347';
    const authToken = '0c440cf8e713a4b486aad73b7207b4c0';

    try {
      const response = await fetch(`https://lookups.twilio.com/v1/PhoneNumbers/${phoneNumber}`, {
        headers: {
          Authorization: `Basic ${btoa(`${accountSid}:${authToken}`)}`,
        },
      });

      if (response.ok) {
        const data = await response.json();
        setName(data.name || 'Name not found');
      } else {
        console.error('Error retrieving information');
        setName('Error');
      }
    } catch (error) {
      console.error('Error:', error);
      setName('Error');
    }
  };

  return (
    <div>
      <input
        type="text"
        placeholder="Enter phone number"
        value={phoneNumber}
        onChange={(e) => setPhoneNumber(e.target.value)}
      />
      <button onClick={handleLookup}>Lookup</button>
      <p>Name: {name}</p>
    </div>
  );
};

export default PhoneNumberLookup;
