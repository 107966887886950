import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Badge from "react-bootstrap/Badge";
import Table from "react-bootstrap/Table";
import axios from "axios";
import moment from "moment";
import { Link, Route, Routes, useNavigate } from "react-router-dom";

const Kennal = () => {
  const [users, setUsers] = useState([]);
  const [totalCounts, setTotalCounts] = useState("");
  const [paidShow, setPaidShow] = useState(false);
  const [status, setStatus] = useState();
  const navigate = useNavigate();
  const userToken = localStorage.getItem("userToken");
  const handleDelete = (item) => {
    const confirmed = window.confirm(
      "Are you sure you want to delete this item?"
    );

    if (!confirmed) {
      return;
    }

    let config = {
      method: "delete",
      maxBodyLength: Infinity,
      url: `http://localhost:3001/auth/v1/post/${item}`,
      headers: {
        Authorization: userToken,
      },
    };

    axios
      .request(config)
      .then((response) => {
        fetchData();
      })
      .catch((error) => {
       
      });
  };

  const fetchData = async (action) => {
    try {
      const response = await axios.get(
        `https://ehb.easyhaibro.com/auth/v1/kennal-all`,
        {
          headers: {
            'Authorization': `Bearer ${userToken}`,
            "Content-Type": "application/json",
          },
        }
      );

    
      setTotalCounts(response.data.data);
      setUsers(response.data.data);
     
    } catch (error) {}
  };

  useEffect(() => {
    fetchData("approve");
  }, []);




  return (
    <>
      <div className="right_sidebar">
        <div className="rs_in_third card border-0">
          <h2 id="total">Kennal Information ({users.length})</h2>
          <table>
            <thead>
              <tr>
                <th>Vet Name</th>
                <th>Vet Number</th>
                <th>Professional Breeder</th>
                <th>Kennal Name</th>
                <th>Kennal Website</th>
                <th>Dog Show</th>
                <th>When Started Breeding</th>
                <th>Vet Pet</th>
                <th>Breed Type</th>
                <th>startBreeding</th>
                <th>Cancellation Policy</th>
                <th>Breeding Practices</th>
                <th>Breed Description</th>
                <th>Created Date</th>
              </tr>
            </thead>
            <tbody>
              {users.map((user, i) => (
                <tr key={user.id}>
                     <td>{user.vetName}</td>
                     <td>{user.vetContact}</td>
                  <td>{user.professionalBreeder}</td>
                  <td>{user.kennalName}</td>
                  <td>{user.kennalWebsite}</td>
                  <td>{user.dogShow}</td>
                  <td>{user.startBreeding}</td>
                  <td>{user.vetPet}</td>
                  <td>{user.breedType}</td>
                  <td>{user.breedingPractices}</td>
                  <td>{user.cancellationPolicy}</td>
                  <td>{user.breedDescription}</td>
                  <td>{user.breedDescription}</td>
                  <td>{moment(user.createdDate).format("DD/MM/YYYY  HH:MM")}</td>
                </tr>
              ))}
            </tbody>
          </table>
        
        </div>
      </div>
    </>
  );
};

export default Kennal;
