import React, { useState, useEffect } from "react";
import axios from "axios";
import Swal from "sweetalert2";

const PostForm = () => {
  const userToken = localStorage.getItem("userToken");
  const [image, setImage] = useState(null);
  const [users, setUsers] = useState([]);
  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    password: "",
    phone: "",
    profileType: "",
    pincode: "",
    currentAddress: "",
    serviceImage: null,
  });
  const [filter, setFilter] = useState("today");

  useEffect(() => {
    fetchUsers();
  }, [filter]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleImageChange = (e) => {
    setImage(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!image) {
      Swal.fire("Error", "Please upload an image.", "error");
      return;
    }

    try {
      const imageFormData = new FormData();
      imageFormData.append('mediaKey', image);

      const imageUploadConfig = {
        headers: {
          'Authorization': `Bearer ${userToken}`,
          'Content-Type': 'multipart/form-data'
        },
      };

      const imageUploadResponse = await axios.post(
        'https://ehb.easyhaibro.com/auth/v1/media-upload',
        imageFormData,
        imageUploadConfig
      );

      setFormData({
        ...formData,
        serviceImage: imageUploadResponse.data.mediaKey,
      });

      const data = { ...formData, serviceImage: imageUploadResponse.data.mediaKey };

      const config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: 'https://ehb.easyhaibro.com/auth/v1/registration',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': userToken,
        },
        data: data
      };

      const response = await axios.request(config);

      Swal.fire("Success", "User created successfully!", "success");
      fetchUsers();
    } catch (error) {
      console.log(error);
      Swal.fire("Error", "There was an error creating the user.", "error");
    }
  };

  const fetchUsers = async () => {
    try {
      const config = {
        headers: {
          'Authorization': `Bearer ${userToken}`
        }
      };

      const response = await axios.get('https://ehb.easyhaibro.com/auth/v1/users', config);
      const usersData = response.data;

      // Apply filter logic here
      const filteredUsers = usersData.filter((user) => {
        const createdDate = new Date(user.createdAt);
        const now = new Date();
        const startOfToday = new Date(now.getFullYear(), now.getMonth(), now.getDate());
        const startOfYesterday = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 1);
        const startOfMonth = new Date(now.getFullYear(), now.getMonth(), 1);
        const startOfLastMonth = new Date(now.getFullYear(), now.getMonth() - 1, 1);
        const startOfYear = new Date(now.getFullYear(), 0, 1);

        if (filter === "today") {
          return createdDate >= startOfToday;
        } else if (filter === "yesterday") {
          return createdDate >= startOfYesterday && createdDate < startOfToday;
        } else if (filter === "thisMonth") {
          return createdDate >= startOfMonth;
        } else if (filter === "lastMonth") {
          return createdDate >= startOfLastMonth && createdDate < startOfMonth;
        } else if (filter === "thisYear") {
          return createdDate >= startOfYear;
        } else {
          return true;
        }
      });

      setUsers(filteredUsers);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="right_sidebar">
      <div className="rs_in_third card border-0">
        <h2>Create User</h2>
        <form onSubmit={handleSubmit}>
          <label>
            Full Name
            <input type="text" name="fullName" placeholder="Full Name" value={formData.fullName} onChange={handleChange} />
          </label>
          <label>
            Email Address
            <input type="text" name="email" placeholder="Email" value={formData.email} onChange={handleChange} />
          </label>
          <br />
          <label>
            Password
            <input type="password" name="password" placeholder="Password" value={formData.password} onChange={handleChange} />
          </label>
          <br />
          <label>
            Current Address
            <input type="text" name="currentAddress" placeholder="Address" value={formData.currentAddress} onChange={handleChange} />
          </label>
          <br />
          <label>
            Pincode
            <input type="number" name="pincode" placeholder="Pincode" value={formData.pincode} onChange={handleChange} />
          </label>
          <br />
          <label>
            Phone
            <input type="tel" name="phone" placeholder="Phone" value={formData.phone} onChange={handleChange} />
          </label>
          <br />
          <label>
            User Profile Type:
            <select name="profileType" value={formData.profileType} onChange={handleChange}>
              <option value="">Select</option>
              <option value="user">User</option>
              <option value="serviceProvider">Service Provider</option>
              <option value="admin">Admin</option>
            </select>
          </label>
          <br />
          <label>
            Upload Image:
            <input type="file" accept="image/*" onChange={handleImageChange} />
          </label>
          <br />
          <button type="submit">Submit</button>
        </form>

        <div className="filter-section">
          <label>
            Filter by:
            <select value={filter} onChange={(e) => setFilter(e.target.value)}>
              <option value="today">Today</option>
              <option value="yesterday">Yesterday</option>
              <option value="thisMonth">This Month</option>
              <option value="lastMonth">Last Month</option>
              <option value="thisYear">This Year</option>
            </select>
          </label>
        </div>

        <div className="user-list">
          <h2>User List</h2>
          <ul>
            {users.map((user) => (
              <li key={user.id}>{user.fullName} - {new Date(user.createdAt).toLocaleDateString()}</li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default PostForm;
